

.tab-header {
    width: 100vw;
}


.add-pairing-event-btn {
    position: fixed;
    top: 0px;
}

.event-ready-chk {
    color: green;
    height: 50px !important;
    width: 50px !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    border-radius: 50%;
    transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}