* {
    padding: 0;
    margin: 0
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:#eee;
}

.animated-check {
    height: 5em;
    width: 5em
}

.animated-check path {
    fill: none;
    stroke: #ef5241;
    stroke-width: 4;
    stroke-dasharray: 23;
    stroke-dashoffset: 23;
    animation: draw 1s linear forwards;
    stroke-linecap: round;
    stroke-linejoin: round
}

@keyframes draw {
    to {
        stroke-dashoffset: 0
    }
}