body {
  background-color: rgb(241, 240, 240);
  height: 100%
}


.MuiOutlinedInput-notchedOutline {
  border-color: black !important;
}

.MuiFilledInput-root {
  border-bottom: 1px solid rgb(18, 18, 18) !important;
}

.MuiInput-root {
  border-bottom: 1px solid black !important;
}

.MuiInputLabel-root {
  color: black !important;
}